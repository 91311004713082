<template>
  <!-- Hero section -->
  <div class="relative h-auto">
    <div class="max-w-7xl mx-auto lg:px-0">
      <div
        class="mx-auto max-w-md px-4 text-center sm:px-6 sm:max-w-3xl lg:px-0 lg:max-w-7xl lg:my-12"
      >
        <typewriter ref="typewriter" :interval="50">
          <h3 class="mt-2 text-4xl font-bold text-gray-900 sm:text-4xl lg:my-8">
            Want to join the team?
          </h3>
          <p class="mt-2 text-xl font-normal text-gray-900 lg:my-8">
            See available positions
          </p>
        </typewriter>
        <div
          class="flex items-center rounded-lg border border-gray-300 p-4 cursor-pointer mb-4 md:w-3/5 mx-auto"
          v-for="position in positions"
          :key="position.key"
        >
          <div class="text-left flex-1" @click="togglePosition(position)">
            <h6 class="font-semibold">{{ position.position }}</h6>
            <h6 class="text-sm">{{ position.location }}</h6>
            
            <collapse-transition group>
              <div
                class="ml-4"
                v-if="position.visible"
                :key="`show-${position.key}`"
              >
                <hr class="border-gray-300 mr-8 my-2" />
                <h2 class="font-normal text-base" v-html="position.position"></h2>
                <h2 class="font-normal text-base" v-html="position.location"></h2>
                <h2 class="font-normal text-base" v-html="position.description"></h2>
                <h2 class="font-normal text-base" v-html="position.type"></h2>
              </div>
            </collapse-transition>
          </div>
          <div class="text-right flex-none">
            <button
              class="flex items-center justify-center px-2 py-1 rounded border border-transparent text-normal font-light shadow-sm text-white bg-blue-600 bg-opacity-100 hover:bg-opacity-80  sm:px-4"
              @click.prevent="showPopup(position)"
            >
              Apply now
            </button>
          </div>
        </div>
        <div class="text-center mt-12">
          <router-link
            class=" items-center justify-center px-2 py-1 rounded border border-transparent text-normal font-light shadow-sm text-white bg-blue-600 bg-opacity-100 hover:bg-opacity-80  sm:px-4"
            to="/jobs"
            >Back</router-link
          >
        </div>
      </div>
    </div>
    <pop-up-interested
      :popupDisplayed.sync="popupDisplayed"
      :position="selectedPosition"
    ></pop-up-interested>
  </div>
</template>

<script>
import typewriter from "vue-type-writer";
import PopUpInterested from "./PopUpInterested.vue";
import { CollapseTransition } from "vue2-transitions";

export default {
  name: "JobsAll",
  components: {
    typewriter,
    PopUpInterested,
    CollapseTransition,
  },
  data: function() {
    return {
      popupDisplayed: "None",
      selectedPosition: null,
      accessToken: "",
      tokenType: "",
      positions: [],
    };
  },
  methods: {
    showPopup(position) {
      this.popupDisplayed = "Interested";
      this.selectedPosition = position;
    },
    togglePosition(position) {
      this.closeAllPositions(position.key);
      this.positions.find(
        (q) => q.key === position.key
      ).visible = !position.visible;
    },
    closeAllPositions(positionKey) {
      for (const position of this.positions) {
        if (positionKey === position.key) {
          continue;
        }
        position.visible = false;
      }
    },
  },
  mounted: function() {
    this.$refs.typewriter.$emit("typewrite");

    const axios = require("axios");
    const data = JSON.stringify({
      grant_type: "client_credentials",
      client_id: 2,
      client_secret: "uy6dbmfPmSqooF85psjSPyKq3mZLAC5HsAxD9h8H",
      scope: "",
    });
    const config = {
      method: "post",
      url: "https://screenit.collok.io/api/oauth/token/",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE",
        "Access-Control-Allow-Headers":
          "Origin, Content-Type, Accept, Authorization, X-Request-With",
      },
      data: data,
    };
    axios(config)
      .then((response) => {
        this.accessToken = response.data.access_token;
        this.tokenType = response.data.token_type;
        const petitionConfig = {
          method: "get",
          url: "https://screenit.collok.io/api/api-positions/",
          headers: {
            Accept: "application/json",
            Authorization: `${this.tokenType} ${this.accessToken}`,
          },
        };
        axios(petitionConfig)
          .then((response) => {
            const locations = response.data.data.reduce(function(
              allStates,
              state
            ) {
              if (state.state.name in allStates) {
                allStates[state.state.name]++;
              } else {
                allStates[state.state.name] = 1;
              }
              return allStates;
            },
            {});
            this.numberOfLocations = Object.entries(locations).length;
            this.numberOfJobs = response.data.data.length;
            this.positions = response.data.data
              .filter((_, index) => index < 10)
              .map((pos, ind) => {
                let margin = "ml-0";
                if (ind === 0 || ind === 3 || ind === 6) {
                  margin = "ml-0";
                } else if (ind === 1 || ind === 4 || ind === 7) {
                  margin = "ml-36";
                } else if (ind === 2 || ind === 5 || ind === 8) {
                  margin = "ml-80";
                }
                return {
                  key: pos.id,
                  position: pos.name,
                  location: pos.state.name,
                  description: pos.description,
                  type: pos.type,
                  company: "",
                  margin,
                  visible: false,
                };
              });
          })
          .catch(function(error) {
            console.error(error);
          });
      })
      .catch(function(error) {
        console.error(error);
      });
  },
};
</script>

<style scoped></style>
