<template>
  <div class="bg-white">
    <main>
      <jobs-all></jobs-all>
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import JobsAll from '@/components/jobs/JobsAll.vue';

export default {
  name: 'AllJobs',
  components: {
    JobsAll,
  },
}
</script>
